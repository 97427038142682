import React from 'react';
function ChatFacebook() {
  return (
    <div>
      <div id="fb-root"></div>
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="108685487297766"
        theme_color="#ffa24b"
        logged_in_greeting="Bonjour, comment puis-je vous aider ?"
        logged_out_greeting="Bonjour, comment puis-je vous aider ?"
      ></div>
    </div>
  );
}

export default ChatFacebook;

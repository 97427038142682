import React, { useContext } from 'react';
import AuthContext from 'contexts/auth';
import PageContext from 'contexts/page';
import { PopoverMenu, PopoverItem } from 'ui/popover';
import Dropdown from 'components/Dropdown';
import Avatar from 'components/Avatar';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NavItemButton = styled(Link)`
  cursor: pointer;
  display: block;
  width: 100%;
  text-decoration: none;
  font-size: 1em;
  text-align: left;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2.5)};
  color: inherit !important;
  transition: color 100ms ease;

  &:hover {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #212121;
  }
`;
const NavLogout=styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  color:#000000 !important; 
  padding: ${({ theme }) => theme.spacing(0.5)}
  ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  cursor: pointer;
  border-radius: 18px;
  border-color:transparent;
  &:hover {
    transition: color 150ms ease-in-out;
    background: ${({ theme }) => theme.primaryLight}  0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #212121;
  }
`;
const UserPopover = () => {
  const { user, handleReset } = useContext(AuthContext);
  const { getLanguagePrefix } = useContext(PageContext);
  const { t } = useTranslation();

  return (
    <Dropdown
      showOverflow
      inHeader
      alignment="right"
      trigger={<Avatar small {...user} />}
    >
      <PopoverMenu>
        <PopoverItem>
          <NavItemButton as={Link} to={getLanguagePrefix(t('url.account'))}>
            {t('header.usePopover.profiles')}
          </NavItemButton>
        </PopoverItem>
        <PopoverItem isBorderTop>
          <NavLogout isResponsiveMenu as="button" onClick={handleReset}>
            {t('header.usePopover.logout')}
          </NavLogout>
        </PopoverItem>
      </PopoverMenu>
    </Dropdown>
  );
};

export default UserPopover;

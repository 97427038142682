import React, { useContext } from 'react';
import styled from 'styled-components';
import { PopoverMenu } from 'ui/popover';
import { Grid } from 'ui';
import Dropdown from 'components/Dropdown';
import { Link } from 'gatsby';
import PageContext from 'contexts/page';
import { getLanguagePrefix as agnosticGetLanguagePrefix } from 'utils';
import { useLocation } from '@reach/router';
import en from '../assets/images/en.png';
import fr from '../assets/images/fr.png';

const DropItem = styled.div`
  font-size: 14px;
  font-weight: 800;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0.2)} 0;
  transition: box-shadow 100ms ease;
  color: ${({ theme }) => theme.text};
`;

const DropItemButton = styled.div`
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  height: 18px;
  background-color: transparent;
  padding: 0;
`;

const Flag = styled.div`
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const LangLink = styled(Link)`

`;
const FlagImg = styled.img`
  cursor: pointer;
  margin-right: 2.5rem;
  width: 1.8rem;
  height: 1.5rem;
  border-radius: 50%;
`;

const LangPopover = () => {
  const {
    language,
    i18nPaths,
    i18nPathsSuffix = '',
  } = useContext(PageContext);

  const languages = [{ name: 'fr' }, { name: 'en' }];

  const location = useLocation();
  const getNextLanguageUri = (lang) => {
    if (Boolean(i18nPaths)) {
      return i18nPaths[lang] + i18nPathsSuffix;
    }

    const currentUri = location.pathname.replace(/$\//, '');

    return agnosticGetLanguagePrefix(
      lang,
      currentUri.replace(agnosticGetLanguagePrefix(language), ''),
    );
  };
  return (
    <Dropdown
      inHeader
      alignment="right"
      width="auto"
      showOverflow
      trigger={
        <DropItem>
          <DropItemButton activeClassName="active">
            <FlagImg src={language === 'fr' ? fr : en} alt="" />
          </DropItemButton>
        </DropItem>
      }
    >
      <PopoverMenu>
        <Grid variant="flags" style={{ width: 60 }}>
          {(languages || []).map((langue, index) => (
            <LangLink key={index} to={getNextLanguageUri(langue.name)}>
              <Flag>
                <FlagImg src={langue.name === 'fr' ? fr : en} alt="" style={{ marginRight: -1, marginLeft:-12}} />
              </Flag>
            </LangLink>
          ))}
        </Grid>
      </PopoverMenu>
    </Dropdown>
  );
};

export default LangPopover;

import React from 'react';
import styled, { css } from 'styled-components';
import { size } from 'ui';
import { get } from 'lodash';
import { API_URL } from 'constants';
import NoneImageProfile from '../assets/images/NoneImageProfile.svg';

const AvatarWrapper = styled.span`
  font-weight: 500;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  color: #fff;
  font-size: ${size(2)};
  background-size: cover;
  background-position: center center;

  ${props =>
    props.tiny &&
    css`
      height: 32px;
      width: 32px;
      font-size: ${size()};
      margin-left: ${({ theme }) => theme.spacing(0.5)};
    `};

  ${props =>
    props.small &&
    css`
      height: 44px;
      width: 44px;
      font-size: ${size()};
    `};

  ${props =>
    props.medium &&
    css`
      height: 48px;
      width: 48px;
      font-size: ${size()};
    `};

  ${props =>
    props.mediumLarge &&
    css`
      height: 64px;
      width: 64px;
      font-size: ${size(2)};
    `};

  ${props =>
    props.white &&
    css`
      background-color: #fff;
      color: #0080c8;
    `};

  ${props =>
    props.big &&
    css`
      height: 96px;
      width: 96px;
      font-size: ${size(5)};
    `};

  ${props =>
    props.huge &&
    css`
      height: 160px;
      width: 160px;
      font-size: ${size(8)};
    `};

  ${props =>
    props.hasShadow &&
    css`
      box-shadow: 0 8px 24px rgba(89, 149, 179, 0.15);
    `};

  ${props =>
    props.empty &&
    css`
      background-color: #ecf0f4;
    `};
`;

const Avatar = ({
  firstName,
  lastName,
  white,
  small,
  style,
  medium,
  big,
  tiny,
  huge,
  hasShaddow,
  empty,
  hasPrimaryBorder,
  hasSecondaryBorder,
  picture,
  name,
}) => (
  <AvatarWrapper
    white={white}
    tiny={tiny}
    small={small}
    medium={medium}
    big={big}
    huge={huge}
    hasShaddow={hasShaddow}
    empty={empty}
    hasPrimaryBorder={hasPrimaryBorder}
    hasSecondaryBorder={hasSecondaryBorder}
    isImage={Boolean(get(picture, 'imagePath'))}
    style={{
      ...style,
      backgroundImage: picture && picture.imagePath ? `url(${API_URL}/${picture.imagePath})` : `url(${NoneImageProfile})`,
    }}
  >

  </AvatarWrapper>
);

Avatar.defaultProps = {
  firstName: '',
  lastName: '',
};

export default Avatar;

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import styled, { css } from 'styled-components';
import Footer from './Footer';
import { ButtonReset, media } from 'ui';
import 'sanitize.css';
import { MdVerticalAlignTop } from 'react-icons/md';
import ChatFacebook from './ChatFacebook';
import SearchBar from 'components/Search/SearchBarAccueil';

const Page = styled.main`
  position: relative;
  ${props =>
    props.noSticky &&
    css`
      margin-top:32px;
  `}
`;

const Wrapper = styled.div`
  min-height: 100vh;
`;

const ScrollButton = styled(ButtonReset)`
  display: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  height: 55px;
  width: 55px;
  transition: all 150ms ease;
  margin-left: 16px;

  &:hover {
    box-shadow: none;
  }

  ${media.tablet`
    display: block;
  `}
`;

const ArrowTop = styled.div`
  svg {
    margin-right: auto;
    margin-left: auto;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  z-index: 100;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    bottom: 16px;
    right: 16px;
    width: auto;
    z-index: 100;
    align-items: right;
    padding: 0;
  `}
`;

const HiddenElement = styled.div``;

export const ScrollToTopButton = ({ scrollRef }) => {
  const scrollToTop = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ScrollButton onClick={scrollToTop}>
      <ArrowTop>
        <MdVerticalAlignTop color="#fff" size={38} />
      </ArrowTop>
    </ScrollButton>
  );
};

const Layout = ({
  children,
  variant,
  footerVariant,
  countries,
  hasSearchBar,
  noSticky
}) => {
  const scrollRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsLoading(true), 2000)
  }, []);
  return (
    <Wrapper>
      <HiddenElement ref={scrollRef} />
      <Header
        variant={variant}
        countries={countries}
        hasSearchBar={hasSearchBar}
      />
      {!noSticky && isLoading &&(
        <SearchBar isSticky />
      )}
      <Page noSticky>
        {children}
        <ButtonsWrapper>
        <ChatFacebook/>

        </ButtonsWrapper>
      </Page>
      <Footer variant="home" />
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

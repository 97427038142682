import React, { useMemo, useState } from 'react';
import { findIndex } from 'lodash';
import slugify from 'slugify';
import {
  Tabs as RawTabs,
  TabList as RawTabList,
  Tab,
  TabPanels as RawTabPanels,
  TabPanel as RawTabPanel
} from '@reach/tabs';
import styled, { css } from 'styled-components';
import { media } from 'ui';
import { useHash } from 'react-use';

export const getUseHash = (...params) => {
  if (typeof window === `undefined`) {
    return () => ['', () => {}];
  }

  return useHash;
};

const TabPanel = styled(RawTabPanel)`
  ${props =>
  props.variant === 'search' &&
  css`
      height:100%;
      width:100%;
    `};
`;

const Tabs = styled(RawTabs)`
  ${props =>
  props.isSplitScreen &&
  css`
      width: 100%;

      ${media.desktop`
      max-width: 800px;
      `}
    `};
  ${props =>
  props.variant === 'search' &&
  css`
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      flex:1;
      height:100%
    `};
`;

const TabPanels = styled(RawTabPanels)`
  margin-top: 425px;
  ${props =>
  props.variant === 'search' &&
  css`
      margin-top: 20px !important;
      flex:1;
      ${props =>
    props.isSticky &&
    css`
        margin-top: 0px !important;
        height:100%;
    `}
  `}
  ${props =>
  props.isSplitScreen &&
  css`
      margin-right: ${({ theme }) => theme.spacing(2)};
      margin-left: ${({ theme }) => theme.spacing(2)};
    `}
    ${media.desktop`
      margin-top: 0px;
      margin-right: 0;
      margin-left: 0;
    `}
`;

const TabList = styled(RawTabList)`
  ${props =>
  props.variant === 'search' &&
  css`
    ${props => props.isSticky &&
    css`
      flex: fit-content 0 0;
    `};
    ${props => !props.isSticky &&
    css`
      width:100%
    `};
  `};
`;

const TabItem = styled(Tab)`
  min-height: 56px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  color: ${({ theme }) => theme.text};
  background-color: #fff;
  font-size: 15px !important;
  text-align: center;
  font-weight: 700 !important;
  text-transform: uppercase;
  outline: none;
  border: none !important;
  padding: 0;
  transition: box-shadow 150ms ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

  ${media.desktop`
    border-bottom: none !important;
    margin-right: ${({ theme }) => theme.spacing(1.5)};
  `}

  ${props =>
  props.variant === 'search' &&
  css`
      white-space: normal;
      text-transform: none;
      box-shadow:none !important;
      padding-left:10px !important;
      padding-right:10px;
      margin-right: 0px;
      ${props =>
        props.sustainable &&
        css`
        border-bottom: 1px solid rgba(0,0,0,0.12) !important;
        ${media.desktop`
          border-bottom: 1px solid rgba(0,0,0,0.12) !important;
        `}
        ${media.mobile`
          padding-bottom: 13px !important;
        `}
      `}
      ${({ isVertical }) =>
    !Boolean(isVertical) &&
    media.desktop`
          padding-left:40px !important;
          padding-right:40px;
          margin-right: ${({ theme }) => theme.spacing(1.5)};
  `}
    `};

  + li {
    margin-right: ${({ theme }) => theme.spacing(1.5)} !important;
  }

  &:hover,
  &[data-selected] {
    box-shadow: inset 0 -4px 0 -1px
      ${props =>
  props.variant !== 'search'
    ? props.theme.primary
    : props.theme.secondary} !important;
    color: ${props =>
  props.variant !== 'search'
    ? props.theme.primary
    : props.theme.secondary} !important;
    background-color: #fff !important;
  }

  a.hover {
    box-shadow: inset 0 -4px 0 ${props => (props.variant !== 'search' ? props.theme.primary : props.theme.secondary)} !important;
    color: ${props =>
  props.variant !== 'search'
    ? props.theme.primary
    : props.theme.secondary} !important;
    background-color: #fff !important;
  }

  ${({ isSticky }) =>
  Boolean(isSticky) &&
  media.desktop`
    min-height: 30px;
    font-size: 12px !important;
    color: grey !important;
    margin-top: 2px;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
    &:hover,  
    &[data-selected] {
      box-shadow: inset -12px 0 0 -10px #FFA24B !important;
      color: #000 !important;
      background-color: #fff !important;
    }

 
  `}
`;

const TabItems = styled(TabList)`
  position: relative;
  background-color: #fff !important;
  display: flex;
  justify-content: left !important;
  flex-direction: column;
  max-width: 90%;
  height: 56px;
  z-index: 1;

  > * {
    padding-left: ${({ theme }) => theme.spacing()};
  }

  ${props =>
  props.variant === 'search' &&
  css`
      justify-content: left !important;
      padding-left:0px;
      flex-direction:row;
    `};
    

  ${({ isSticky }) =>
  Boolean(isSticky) &&
  css`
      height:100%;
      flex-direction:column;
  `}

  ${({ isSticky }) =>
  !Boolean(isSticky) &&
  media.desktop`
    position: sticky;
    top: ${({ theme }) => theme.spacing(0)};
    justify-content: left !important;
    flex-direction: row;
    height: 56px;

    > * {
      padding-left: 0;
    }

    ${props =>
      props.destination &&
      css`
        justify-content: right !important;
        max-width: 96%;
        margin-bottom: 10px;
      `}
  `}

  ${({ isSticky }) =>
  !Boolean(isSticky) &&
  media.mobile`
    ${props =>
      props.destination &&
      css`
        justify-content: center !important;
      `}
  `}

  

  a {
    text-decoration: none;
    min-height: 68px;
    display: flex;
    align-items: center;
  }
`;

const MyTabs = ({
                  hasNavigation,
                  basePath,
                  tabs,
                  contents,
                  hotel,
                  variant,
                  isSplitScreen,
                  setTabIndex,
                  isSticky,
                  sustainable,
                  destination
                }) => {
  const useHash = getUseHash();
  const [hash, setHash] = useHash();
  const tab = hash.split('#')?.[1];
  const selectedTab = (typeof localStorage !== 'undefined') ? localStorage.getItem('tabSelected') : "{}";
  const [tabSearch, setTabSearch] = useState(JSON.parse(selectedTab));
  const slugifiedTabs = useMemo(
    () =>
      tabs.map(tab => ({
        ...tab,
        slug: tab?.label && slugify(tab.label, { lower: true }),
      })),
    [tabs],
  );

  if (tabSearch == null) {
    setTabSearch(0);
  }
  const defaultIndex = useMemo(() => {
    if (!tab) {
      if (setTabIndex) {
        setTabIndex(0);
      }
      return tabSearch;
    }
    const index = findIndex(slugifiedTabs, { slug: tab });
    if (setTabIndex) {
      setTabIndex(index === -1 ? 0 : index);
    }
    return index === -1 ? 0 : index;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugifiedTabs, tab]);

  const handleChange = index => {
    localStorage.setItem('tabSelected', JSON.stringify(index));
    if (setTabIndex) {
      setTabIndex(index);
    }
    if (!hasNavigation) {
      return;
    }

    const tab = slugifiedTabs[index];
    setHash(`#${tab.slug}`);
  };

  return (
    <>
      <Tabs
        isSplitScreen={isSplitScreen}
        defaultIndex={defaultIndex}
        onChange={handleChange}
        variant={variant}
      >
        <TabItems
          style={
            hotel
              ? { width: 'auto' }
              : variant !== 'search'
              ? { width: '1200px' }
              : {  }
          }
          variant={variant}
          isSticky={isSticky}
          destination={destination}
        >
          {tabs.map(
            (tab, index) =>
              tab && (
                <TabItem key={index} variant={variant} isSticky={isSticky} sustainable= {sustainable}>
                  {tab.label}
                </TabItem>
              ),
          )}
        </TabItems>

        {Boolean(hotel) ? (
          <TabPanels
            isSplitScreen={isSplitScreen}
            variant={variant}
            isSticky={isSticky}
          >
            {contents.map((content, index) => (
              <TabPanel key={index} style={{ outline: 'none' }} variant={variant}>
                {content}
              </TabPanel>
            ))}
          </TabPanels>
        ) : (
          <TabPanels variant={variant} isSticky={isSticky}>
            {contents.map((content, index) => (
              <TabPanel key={index} style={{ outline: 'none' }} variant={variant}>
                {content}
              </TabPanel>
            ))}
          </TabPanels>
        )}
      </Tabs>
    </>
  );
};

export default MyTabs;

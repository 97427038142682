import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PageContext from 'contexts/page';
import { deburr, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

const CountryName = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.text};
`;

const Flag = styled.div`
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const CountryLink = styled(Link)`
  text-decoration: none;
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(0)};

  &:hover {
    transition: color 150ms ease-in-out;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #212121;
  }
`;
const MoreCountryLinkStyle = styled(Link)`
  text-decoration: none;
  display: flex;
  width: 300px !important;
  color: #000 !important;
  &:hover {
    transition: color 150ms ease-in-out;
    opacity: 1;
    color: #000000;
  }
`;

const CountryFlagsMobile = ({ variant, countries, home }) => {
  const { getLanguagePrefix } = useContext(PageContext);
  const sortedCountries = orderBy(countries, ({ title }) => deburr(title));

  const { t } = useTranslation();

  return (
    <div>
      <MoreCountryLinkStyle
        variant="moreDestination"
        as={Link}
        to={getLanguagePrefix(t('url.countries'))}
      >
        {t('home.moredestinnation.button')}
      </MoreCountryLinkStyle>
      {(sortedCountries.slice(0, 6) || []).map((country, index) => (
        <CountryLink key={index} to={getLanguagePrefix(country.slug)}>
          <Flag>
            <span
              className={`flag-icon flag-icon-${(
                country.alpha2 || ''
              ).toLowerCase()}`}
            ></span>
          </Flag>
          <CountryName>{country.title}</CountryName>
        </CountryLink>
      ))}
    </div>
  );
};

export default CountryFlagsMobile;

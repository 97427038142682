import React, { useState, useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Field } from '../ui/forms';
import { FormLabel } from 'ui/forms';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-dates/initialize';
import '../styles/dates.css';
import { SingleDatePicker } from 'react-dates';
import { media } from 'ui';

moment.locale('fr');

const DatesPickerStyles = createGlobalStyle`

  .SingleDateField .SingleDatePickerInput_clearDate {
    width:40px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  
  .SingleDateField .SingleDatePickerInput_clearDate_svg {
    width:15px;
    height:15px;
  }
  
  .SingleDateField .DayPicker_transitionContainer, .DayPicker_transitionContainer_1, .DayPicker_transitionContainer__horizontal, .DayPicker_transitionContainer__horizontal_2 {
    position: absolute !important;
    top: 0;
    left: -30px;

    ${props =>
  Boolean(props.isInWidget) &&
  css`
        left: -5px;
      `}
  }
  .DateInput_input__focused {
    ${props =>
      props.variant === 'profile' &&
      css`
        border: 2px solid rgb(243 244 246) !important;
        border-radius : 10px;
        padding: 15px 5px 15px 8px;
      `}
  }
  .SingleDateField .DayPicker_weekHeader, .DayPicker_weekHeader_1 {
    position: relative;
    top: 60px;
    left: -30px !important;

    ${props =>
  Boolean(props.isInWidget) &&
  css`
        left: -5px !important;
      `}
  }

  .SingleDateField .DayPickerNavigation_rightButton__horizontalDefault {
    top: 20px;
    right: 62px;

    ${props =>
  Boolean(props.isInWidget) &&
  css`
        right: 22px;
      `}
  }
  .SingleDateField .DayPickerNavigation_leftButton__horizontalDefault {
    top: 20px;
    left: 0;

    ${props =>
  Boolean(props.isInWidget) &&
  css`
        left: 20px;
      `}
  }

  .SingleDateField .CalendarMonth, .CalendarMonth_1 {
    padding-left: 17px !important;
    padding-right: 16px !important;
    padding-bottom: 16px !important;
  }

  .SingleDateField .DayPicker_weekHeaders {
    position: absolute;
  }

  .SingleDateField .DayPicker_transitionContainer {
    border-radius: 0;
  }

  .SingleDateField .DateRangePickerInput__withBorder.DateRangePickerInput__withBorder {  
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    height: 48px;

    ${props =>
  props.variant === 'home' &&
  css`
        height: 100%;
        border: none;
        border-radius: 0;
        height: 100%;
      `};

      ${props =>
  props.variant === 'search' &&
  css`
          width: 100%;
          input {
            max-width: 100px;
          }

          ${media.desktop`
            input {
              max-width: 100px;
            }
          `}
        `};
  }
  .SingleDateField .SingleDatePicker{
    width:100%;
    height: 100%;
  }
  .SingleDateField .SingleDatePicker > div{
    height: 100%;
    width:100%;
  }
  .SingleDateField .SingleDatePickerInput{
    width:100%;
    height: 100%;
  }
  .SingleDateField .SingleDatePickerInput > .DateInput{
    width:100%;
    height: 100%;
  }
  
 
  .SingleDateField .DateInput_input.DateInput_input {
    color: ${({ theme }) => theme.text} !important;
    height: 100%;
    font-weight: 400 !important;
    font-size: 16px !important;
    text-align: left !important;
    max-width: 120px !important;
    ${props =>
      props.variant === 'profile' &&
      css`
        border-bottom: 2px solid rgb(243 244 246) !important ;
        padding-bottom: 10px;
        padding-top: 10px;
      `}
    ${props =>
  props.full &&
  css`
        max-width:none !important;
        width: 100% !important;
      `}

    ::placeholder {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.textLight} !important;
      text-transform: capitalize;
      font-size: 14px !important;
      text-align: left !important;
    }

`;

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;

  ${props =>
  props.variant === 'natural' &&
  css`
      width: auto;
      height: 36px;
    `}

  ${props =>
  props.variant === 'search' &&
  css`
      margin-top: 0;
      height: 48px;
      width: auto;
    `}
    
  ${props =>
  props.variant === 'booking' &&
  css`
     border: 1px solid ${({ theme }) => theme.separator};
     background: transparent;
     height: 48px;
     border-radius: 15px;
     padding-left:5px;
  `}
`;

const DateInput = ({
                     label,
                     id,
                     style,
                     flex,
                     className,
                     variant,
                     value,
                     onChange,
                     full,
                     datePlaceholder,
                     isVertical,
                     isInWidget,
                     otherDate,
                     isStart,
                     clearDateDisabled,
                     allowPast,
                     allowFuture,
                     isMonthAndYearSelectionnable,
                     required,
                     actionOnfocus
                   }) => {
  const [focusedInput, setFocusedInput] = useState();
  const date = value || undefined;

  const initialVisibleMonth = () => {
    if (Boolean(date)) {
      return moment(date);
    }
    if (!isStart && Boolean(otherDate)) {
      return moment(otherDate).add(1,'days');
    }
    return moment();
  }
  const isDayBlocked = (day) => {
    if (allowPast && day.isBefore(moment())) {
      return false;
    }
    if (!allowFuture && day.isAfter(moment().subtract('day', 1))) {
      return true;
    }
    if (Boolean(otherDate) && Boolean(day)) {
      if (!isStart) {
        return day.isSameOrBefore(otherDate);
      }
    }
    return day.isBefore(moment().subtract('day', 1));
  }

  useEffect(() => {
    onChange && onChange(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (!isStart && otherDate && moment(otherDate).isAfter(date))
      onChange(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherDate]);

  const returnYears = () => {
    let years = []
    if (allowPast) {
      for(let i = moment().year() - 100; i < moment().year(); i++) {
        years.push(<option value={i}>{i}</option>);
      }
    }
    years.push(<option value={moment().year()}>{moment().year()}</option>);
    if (allowFuture) {
      for(let i = moment().year() +1 ; i <= moment().year() + 100; i++) {
        years.push(<option value={i}>{i}</option>);
      }
    }


    return years;
  }

  const renderMonthElement = isMonthAndYearSelectionnable? ({ month, onMonthSelect, onYearSelect }) =>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {returnYears()}
        </select>
      </div>
    </div> : undefined

  return (
    <Wrapper variant={variant} className={'SingleDateField'}>
      <DatesPickerStyles
        isVertical={isVertical}
        variant={variant}
        full={full}
        isInWidget={isInWidget}
      />
      {label && <FormLabel>{label}</FormLabel>}
      <SingleDatePicker
        id={id}
        date={Boolean(date) ? moment(date) : null}
        verticalHeight={650}
        numberOfMonths={1}
        showClearDate={!clearDateDisabled}
        onDateChange={date => {
          onChange(date ? date.format() : null);
        }}
        initialVisibleMonth={initialVisibleMonth}
        focused={focusedInput}
        placeholder={datePlaceholder}
        onFocusChange={({ focused }) => {
                                          setFocusedInput(focused); 
                                          actionOnfocus(); }}
        style={{height:'100%'}}
        noBorder={true}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isDayBlocked}
        hideKeyboardShortcutsPanel={true}
        renderMonthElement={renderMonthElement}
      />
    </Wrapper>
  );
};

export const DateField = props => (
  <>
    <Field {...props}>
      {({ field, form: { setFieldValue } }) => (
        <DateInput
          {...props}
          {...field}
          onChange={value => {
            setFieldValue(field.name, value);
          }}
          actionOnfocus = {props.actionOnfocus}
        />
      )}
    </Field>
  </>
);

export default DateInput;

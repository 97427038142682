import styled, { css } from 'styled-components';
import { FieldTitle } from '../../ui/forms';
import React from 'react';
import { media } from 'ui';

const FieldTitleContainer = styled.div`
  display:flex;
  flex-direction: column;
  height:73px;
  box-sizing:border-box;
  ${({ showBorder }) =>
    Boolean(showBorder) &&
    media.desktop`
     padding-right:5px;
     border-right: 1px solid rgb(194, 194, 194);
  `}
  
  ${({ isSticky }) =>
    Boolean(isSticky) &&
    css`
      height: 100%;
    `}
  ${({ flex }) =>
    media.desktop`
     flex:${flex};
  `}
  ${({ flexMobile }) =>
    flexMobile &&
    css`
      flex: ${flexMobile};
    `}
`;

const FieldWithTitle = props => {
  return (
    <FieldTitleContainer
      isSticky={props.isSticky}
      variant={props.variant}
      showBorder={props.showBorder}
      flex={props.flex}
      flexMobile={props.flexMobile}
    >
      <FieldTitle variant={props.WidgetTitle}>{props.title}</FieldTitle>
      {props.children}
    </FieldTitleContainer>
  );
};

export default FieldWithTitle;

import React, { useContext, useRef, useState, useEffect } from 'react';
import AuthContext from 'contexts/auth';
import styled, { css } from 'styled-components';
import 'moment/locale/fr';
import { media } from 'ui';
import { useTranslation } from 'react-i18next';
import MyTabs from 'components/MyTabs';
import PageContext from '../../contexts/page';
import logo from '../../assets/images/colorSimpleLogo.png';
import { Link } from 'gatsby';
import ChampsRecherche from './ChampsRecherche';
import RecherchePersonnaliseeHorsConnection from './RecherchePersonnaliseeHorsConnection';

export const SearchBarContainer = styled.div`
  ${({ isSticky }) =>
  Boolean(isSticky) &&
  css`
      height: 100%;
    `}
`;

export const FormContainer = styled.div`
  max-height: 100% !important;
  display: flex;
  align-items: center;
  border: 10px solid #ffffff;
  background: white;
  border-radius: 25px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  max-width: 2000px;
  ${media.desktop`
    width: 90%;
  `};
  z-index: 3;
  ${({ isSticky }) =>
  Boolean(isSticky) &&
  css`
      z-index: 4;
      visibility: collapse;
      ${media.desktop`
        visibility:visible;
      `};
      width: 97%;
      position: absolute;
      top: 10px;
      border-radius: 65px;
      height: 85px;
      content-sizing:border-box;
      padding-bottom: 0px !important;
    `}

  ${media.desktop`
    margin-left: 0px !important;
    margin-right: 0px !important;
  `};
`;

const Logo = styled(Link)`
  flex: fit-content 0 0;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: #000;

  > img {
    width: 89px;
  }
`;

const BarreRecherche = styled.div`
  display: flex;
  justify-content: center;

  ${({ isVertical, isSticky }) =>
  !Boolean(isVertical) &&
  !Boolean(isSticky) &&
  media.desktop`
      width: 93%;
      margin-left: auto;
      margin-right: auto;
    `};
  ${({ isResultPage }) =>
  Boolean(isResultPage) &&
  media.desktop`
      display: flex;
      flex-direction: row;
    width: 95%;
      margin-left: auto;
      margin-right: auto;
    `};
`;

const SearchBar = ({
                     id,
                     initialValues,
                     isVertical,
                     isSticky,
                     isResultPage,
                   }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [stickyClass, setStickyClass] = useState('');
  const { language } = useContext(PageContext);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 50 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };
  const searchBarRef = useRef();

  const tabElements = [
    { label: t('newSearchBar.typeSearch1') },
    { label: t('newSearchBar.typeSearch2') },
  ];

  const tabsContent = [
    <SearchBarContainer isSticky={isSticky}>
      <ChampsRecherche
        isVertical={isVertical}
        initialValues={initialValues}
        id={id}
        isSticky={isSticky}
      />
    </SearchBarContainer>,
    Boolean(user) ? (
      <SearchBarContainer isSticky={isSticky}>
        <ChampsRecherche
          isVertical={isVertical}
          showProfileData={true}
          initialValues={initialValues}
          id={id}
          isSticky={isSticky}
        />
      </SearchBarContainer>
    ) : (
      <SearchBarContainer isSticky={isSticky}>
        <RecherchePersonnaliseeHorsConnection
          isVertical={isVertical}
          isSticky={isSticky}
        />
      </SearchBarContainer>
    ),
  ];

  return (
    <BarreRecherche isSticky={isSticky} isResultPage={isResultPage}>
        <FormContainer
          ref={searchBarRef}
          isSticky={isSticky}
          className={isSticky ? `navbar ${stickyClass}` : ''}
        >
          {isSticky ? (
            <Logo to={!language === 'fr' ? '/en' : '/'}>
              <img src={logo} alt="feelingo" />
            </Logo>
          ) : (
            ''
          )}
          <MyTabs
            isSticky={isSticky}
            isVertical={isVertical}
            tabs={tabElements}
            contents={tabsContent}
            variant="search"
          />
        </FormContainer>
    </BarreRecherche>
  );
};

export default SearchBar;

import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { PopoverMenu } from 'ui/popover';
import { Grid} from 'ui';
import Dropdown from 'components/Dropdown';
import { Link } from 'gatsby';
import PageContext from 'contexts/page';
import { deburr, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import listing from '../assets/images/listing.svg';
const DropItem = styled.div`
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(0.2)} 0;
  transition: box-shadow 100ms ease;
  color: ${({ theme }) => theme.text};

  ${props =>
    props.variant === 'hero' &&
    css`
      border-color: #fff;
      color: #fff;
    `}
`;

const DropItemButton = styled.div`
  outline: none;
  text-decoration: none;
  height: 18px;
  background-color: transparent;
  padding: 0;
  font-size: 16px;
  ${props =>
    props.variant === 'country' &&
    css`
     color:#000000 !important; 

  `}
`;

const CountryName = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.text};
`;

const Flag = styled.div`
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const CountryLink = styled(Link)`
  text-decoration: none;
  display: flex;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1.5)};

  &:hover {
      transition: color 150ms ease-in-out;
      background: #F7F7F7 0% 0% no-repeat padding-box;
      border-radius: 18px;
      opacity: 1;
      color: #212121;
  }
`;
const MoreCountryLinkStyle=styled(Link)`
  text-decoration: none;
  display: flex;
  color:#000000 !important; 
  padding: ${({ theme }) => theme.spacing(0.5)}
  ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  padding-left: 40%;
  &:hover {
    transition: color 150ms ease-in-out;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #212121;
  }
`;

const CountryFlagsPopover = ({ variant, countries,home }) => {
  const { getLanguagePrefix } = useContext(PageContext);
  const sortedCountries = orderBy(countries, ({ title })  => deburr(title));

  const { t } = useTranslation();

  return (
    <Dropdown
      inHeader
      alignment="center"
      width="auto"
      showOverflow
      trigger={
        <DropItem variant={variant}>
          <DropItemButton variant="country" activeClassName="active">
            {t('newHeader.destination')}<img alt="" src={listing} style={{paddingLeft:"8px"}}/>
          </DropItemButton>
        </DropItem>
      }
    >
      <PopoverMenu>
        <Grid variant="flags">
          {(sortedCountries.slice(0,6) || []).map((country, index) => (
            <CountryLink key={index} to={getLanguagePrefix(country.slug)}>
              <Flag>
                <span
                  className={`flag-icon flag-icon-${(
                    country.alpha2 || ''
                  ).toLowerCase()}`}
                ></span>
              </Flag>
              <CountryName>{country.title}</CountryName>
            </CountryLink>
          ))}

        </Grid>
        <MoreCountryLinkStyle
             as={Link}
             to={getLanguagePrefix(t('url.countries'))}
            >
              {t('home.moredestinnation.button')}
         </MoreCountryLinkStyle>
      </PopoverMenu>
    </Dropdown>
  );
};

export default CountryFlagsPopover;

import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { useAsync } from 'react-async';
import { navigate } from '@reach/router';
import { Formik } from 'formik';
import { pickBy } from 'lodash';
import { Form as RawForm } from 'components/Forms';
import { DateField } from 'components/SingleDateField';
import LocationField from 'components/LocationField';
import { formatSearch } from 'utils';
import { getProfiles } from 'api/profiles';
import SelectField from 'components/Select';
import { Button as RawButton, media } from 'ui';
import UtilsContext from 'contexts/util';
import SearchAges from 'components/SearchAges';
import format from 'date-fns/format';
import styled from 'styled-components';
import AuthContext from 'contexts/auth';
import FieldWithTitle from './FieldWithTitle';

const Form = styled(RawForm)`
  height: 100%;
`;

const Button = styled(RawButton)`
  height: 55px;
`;
export const ButtonContainer = styled.div`
  flex: content 0 0;
  ${media.desktop`
  z-index: 2;
 `}
`;

export const ButtonWrapper = styled.div`
  color: #fff;
  font-weight: bold;
  svg {
    display: none;
    margin: 0;
  }

  text-transform: none;

  p {
    display: block;
  }

  ${({ isVertical }) =>
    !Boolean(isVertical) &&
    media.desktop`
    display: flex;
    align-items: center;
    justify-content: center;

      svg {
        display: block;
        font-size: 24px;
      }

  `};
`;

export const SearchWrapper = styled.div`
  border-radius: 4px;
  background: #fff;
  display: flex;
  height: 100%;
  flex-direction: column;
  ${media.desktop`
    flex-direction:row;
    gap:1%;
    align-items:center;
 `};
`;

const ChampsRecherche = ({
  isVertical,
  showProfileData,
  isSticky,
  initialValues,
  id,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { data: { profiles } = {} } = useAsync({
    promiseFn: getProfiles,
    watch: user,
  });

  const profilesOption = (profiles || []).map(({ displayName, id }) => ({
    label: displayName,
    value: id,
  }));

  const { onClose } = useContext(UtilsContext);
  const handleSearch = values => {
    const { startDate, endDate, ...rest } = values;
    const params = {
      ...rest,
      guests: 2,
      ...(startDate &&
        endDate && {
          startDate: format(startDate, 'YYYY-MM-DD'),
          endDate: format(endDate, 'YYYY-MM-DD'),
        }),
    };
    if (
      params.ageCategories.adults === 0 &&
      params.ageCategories.children === 0
    ) {
      params.ageCategories.adults = 2;
    }
    const search = formatSearch(params);

    localStorage.setItem('myData', JSON.stringify(params));
    navigate(`/search?${search}`);

    onClose();
  };

  const saisieSearch = (typeof localStorage !== 'undefined') ? JSON.parse(localStorage.getItem('myData')) : {};

  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValues
          ? pickBy({
              ...initialValues,
              guests: Number(initialValues?.guests),
              profile: initialValues?.profile && Number(initialValues?.profile),
              sustainability:
                initialValues?.sustainability &&
                Number(initialValues?.sustainability),
              ageCategories: {
                adults: initialValues?.ageCategories?.adults ?? 0,
                children: initialValues?.ageCategories?.children ?? 0,
                infants: initialValues?.ageCategories?.infants ?? 0,
              },
            })
          : saisieSearch
          ? {
              ageCategories: {
                adults: saisieSearch.ageCategories?.adults,
                children: saisieSearch.ageCategories?.children,
                infants: saisieSearch.ageCategories?.infants,
              },
              startDate: saisieSearch.startDate,
              endDate: saisieSearch.endDate,
              location: saisieSearch.location,
              profile: saisieSearch.profile,
              guests: Number(saisieSearch.guests),
              countryId: saisieSearch.countryId,
              cityId: saisieSearch.cityId,
              regionId: saisieSearch.regionId,
            }
          : {
              ageCategories: {
                adults: 0,
                children: 0,
              },
            }
      }
    >
      {({ values }) => (
        <Form
          variant="search"
          isVertical={isVertical}
          id={id}
          isSticky={isSticky}
          onSubmit={e => {
            e.preventDefault();
            handleSearch(values);
          }}
        >
          <SearchWrapper
            isVertical={isVertical}
            isSticky={isSticky}
            isModal={false}
            variant="home"
          >
            {Boolean(user) && showProfileData && (
              <FieldWithTitle
                title={t('newSearchBar.profile.title')}
                isSticky={isSticky}
                showBorder={true}
                flex={'0 1 250px'}
              >
                <SelectField
                  name="profile"
                  isVertical={isVertical}
                  variant="homeSearch"
                  placeholder={t('newSearchBar.profile.text')}
                  isSticky={isSticky}
                  options={
                    profilesOption.length > 0
                      ? [
                          { label: t('searchBar.unselected'), value: '' },
                          ...profilesOption,
                        ]
                      : [
                          {
                            label: (
                              <Button
                                variant="secondary"
                                onClick={e => {
                                  e.preventDefault();
                                  navigate('/profile-walkthrough');
                                }}
                              >
                                {t('profiles.account.create')}
                              </Button>
                            ),
                          },
                        ]
                  }
                  type="none"
                />
              </FieldWithTitle>
            )}
            <FieldWithTitle
              title={t('newSearchBar.place.title')}
              isSticky={isSticky}
              showBorder={true}
              flex={'1 0 150px'}
            >
              <LocationField
                name="location"
                variant="homeSearch"
                full={true}
                placeholder={t('newSearchBar.place.text')}
                type="location"
                isVertical={isVertical}
              />
            </FieldWithTitle>
            <FieldWithTitle
              title={t('newSearchBar.arrival.title')}
              isSticky={isSticky}
              showBorder={true}
              flex={'1 1 100px'}
            >
              <DateField
                variant="home"
                id="start"
                name="startDate"
                datePlaceholder={t('newSearchBar.arrival.text')}
                isVertical={isVertical}
                otherDate={values.endDate}
                isStart={true}
                isSticky={isSticky}
                full={true}
                allowFuture
                actionOnfocus={function() {
                  return null
                }}
              />
            </FieldWithTitle>
            <FieldWithTitle
              title={t('newSearchBar.leave.title')}
              isSticky={isSticky}
              showBorder={true}
              flex={'1 1 100px'}
            >
              <DateField
                id="end"
                variant="home"
                name="endDate"
                datePlaceholder={t('newSearchBar.leave.text')}
                isVertical={isVertical}
                otherDate={values.startDate}
                isStart={false}
                isSticky={isSticky}
                full={true}
                allowFuture
                actionOnfocus={function() {
                  return null
                }}
              />
            </FieldWithTitle>
            <FieldWithTitle
              title={t('newSearchBar.travellers.title')}
              isSticky={isSticky}
              flex={'1 0 200px'}
            >
              <SearchAges
                name="ageCategories"
                variant="homeSearch"
                placeholder={t('newSearchBar.travellers.text')}
                isSticky={isSticky}
              />
            </FieldWithTitle>
            <ButtonContainer>
              <Button
                style={isVertical && { width: '100%' }}
                variant="homeSearch"
                isSticky={isSticky}
              >
                <ButtonWrapper isVertical={isVertical}>
                  <p style={{ color: 'black' }}>
                    {t('newSearchBar.buttons.recherche')}
                  </p>
                </ButtonWrapper>
              </Button>
            </ButtonContainer>
          </SearchWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ChampsRecherche;

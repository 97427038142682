import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PageContext from '../contexts/page';
import { getLanguagePrefix as agnosticGetLanguagePrefix } from '../utils/index';
import { useLocation } from '@reach/router';
import en from '../assets/images/en.png';
import fr from '../assets/images/fr.png';

const Flag = styled.div`
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const LangLink = styled(Link)``;

const FlagImg = styled.img`
  cursor: pointer;
  margin-right: 0px;
  width: 50px;
  height: 48px;
  border-radius: 50%;
`;

const BlockLang = styled.div`
  padding: 20px;
  margin-top: -60px;
  line-height: 70px;
`;

const LangPopoverMobile = () => {
  const {
    language,
    i18nPaths,
    i18nPathsSuffix = '',
  } = useContext(PageContext);

  const languages = [{ name: 'fr', }, { name: 'en' }];

  const location = useLocation();
  const getNextLanguageUri = lang => {
    if (Boolean(i18nPaths)) {
      return i18nPaths[lang] + i18nPathsSuffix;
    }

    const currentUri = location.pathname.replace(/$\//, '');

    return agnosticGetLanguagePrefix(
      lang,
      currentUri.replace(agnosticGetLanguagePrefix(language), ''),
    );
  };
  return (
    <BlockLang>
      {(languages || []).map((langue, index) => (
        <LangLink key={index} to={getNextLanguageUri(langue.name)}>
          <Flag>
            <FlagImg
              src={langue.name === 'fr' ? fr : en}
              alt=""
              style={{ marginRight: -1, marginLeft: -12 }}
            />
          </Flag>
        </LangLink>
      ))}
    </BlockLang>
  );
};

export default LangPopoverMobile;

import React, { useContext, useState, useEffect } from 'react';
import AuthContext from 'contexts/auth';
import Modal from 'components/Modal';
import AuthForm from './AuthForm';
import { useQueryParams } from 'hooks';
import { camelizeKeys } from 'humps';

const AuthTrigger = ({ children, onAuth }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const queryParams = camelizeKeys(useQueryParams());

  const isRegistering = Boolean(queryParams.register);
  const isPasswordNew = Boolean(queryParams.resetToken);

  const handleTrigger = () => {
    if (isAuthenticated) {
      return onAuth();
    }
    setIsOpen(true);
  };

  const handleCloseModal = () => setIsOpen(false);

  useEffect(() => {
    if (Boolean(isRegistering) || Boolean(isPasswordNew)) {
      setIsOpen(true);
    }
  }, [isRegistering, isPasswordNew]);

  return (
    <>
      {children({ handleTrigger })}
      {isOpen && (
        <Modal isOpen scrollable={true} onRequestClose={handleCloseModal} variant="auth">
          <AuthForm
            onLoginSuccess={onAuth}
            isRegistering={isRegistering}
            isPasswordNew={isPasswordNew}
          />
        </Modal>
      )}
    </>
  );
};

export default AuthTrigger;

import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import PageContext from '../../contexts/page';
import { navigate } from '@reach/router';
import AuthTrigger from 'components/AuthTrigger';
import { NavLink } from 'ui';
import styled from 'styled-components';
import { Button as RawButton, media } from 'ui';
import { getProfiles } from 'api/profiles';

export const AccountWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  ${({ isSticky }) =>
  !Boolean(isSticky) &&
  media.desktop`
    margin-left:5%;
  `};
  ${media.desktop`
    flex-direction:row;
    height:100%;
  `};
`;

export const Button = styled(RawButton)`
  height:55px;
  margin-top:10px;
    ${media.desktop`
    margin-top:0px;
    margin-left:10px;
  `};
`;

export const ButtonWrapper = styled.div`
  color: #fff;
  font-weight: bold;
  svg {
    display: none;
    margin: 0;
  }

  text-transform: none;

  p {
    display: block;
  }

  ${({ isVertical }) =>
  !Boolean(isVertical) &&
  media.desktop`
    display: flex;
    align-items: center;
    justify-content: center;

      svg {
        display: block;
        font-size: 24px;
      }

  `};
`;

const TextRecherchePersonnaliseeHorsConnection = styled.div`
  width: 100%;
  text-align: center;
  ${media.desktop`
    max-width: 40%;
    text-align:left;
  `};
`;

const LienTextRecherchePersonnaliseeHorsConnection = styled.div`
  width: 100%;
  text-align: center;
  margin-top:10px;
    ${media.desktop`
    margin-top:0px;
    margin-left:10px;
  `};
`;

const RecherchePersonnaliseeHorsConnection = ({ isVertical, isSticky }) => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);
  return (
    <AccountWrapper
      isVertical={isVertical}
      isModal={false}
      variant="home"
      isSticky={isSticky}
    >
      <TextRecherchePersonnaliseeHorsConnection>{t('newSearchBar.text')}</TextRecherchePersonnaliseeHorsConnection>
      <AuthTrigger
        onAuth={async () => {
          const profiles = await getProfiles();
          if (profiles.length < 1) {
            navigate('/profile-walkthrough');
          }
        }}
      >
        {({ handleTrigger }) => (
          <Button
            variant="homeSearch"
            onClick={e => {
              e.preventDefault();
              handleTrigger();
            }}
          >
            <ButtonWrapper isVertical={isVertical}>
              <p>{t('newSearchBar.buttons.profil')}</p>
            </ButtonWrapper>
          </Button>
        )}
      </AuthTrigger>

      <NavLink to={getLanguagePrefix(t('url.howItWorks'))} secondary>
        <LienTextRecherchePersonnaliseeHorsConnection>{t('newSearchBar.link')}</LienTextRecherchePersonnaliseeHorsConnection>
      </NavLink>
    </AccountWrapper>
  );
};

export default RecherchePersonnaliseeHorsConnection;

import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import PageContext from 'contexts/page';
import { PopoverMenu, PopoverItem } from 'ui/popover';
import Dropdown from 'components/Dropdown';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import listing from '../assets/images/listing.svg';
const DropItem = styled.div`
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(0.2)} 0;
  transition: box-shadow 100ms ease;
  color: ${({ theme }) => theme.text};

  ${props =>
    props.variant === 'hero' &&
    css`
      border-color: #fff;
      color: #fff;
    `}
`;

const DropItemButton = styled.div`
  outline: none;
  text-decoration: none;
  height: 18px;
  background-color: transparent;
  padding: 0;
  color:#000000;
  font-size: 16px;
`;

const NavItemButton = styled(Link)`
  cursor: pointer;
  display: block;
  width: 100%;
  text-decoration: none;
  font-size: 1em;
  text-align: inherit;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2.5)};
  color: inherit !important;
  transition: color 100ms ease;

  &:hover {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #212121;
  }
`;
const FeelingoJobPopover = ({ variant }) => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);
  return (
    <Dropdown
      inHeader
      alignment="center"
      trigger={
        <DropItem  >
          <DropItemButton>
            {t('newHeader.whoAreWe')}<img alt="" src={listing} style={{paddingLeft:"8px"}}/>
          </DropItemButton>
        </DropItem>
      }
    >
      <PopoverMenu>
        <PopoverItem>
          <NavItemButton to={getLanguagePrefix(t('url.aboutUs'))}>
            {t('header.feelingoJobPopover.aboutUs')}
          </NavItemButton>
        </PopoverItem>
        <PopoverItem>
          <NavItemButton to={getLanguagePrefix(t('url.manifesto'))}>
            {t('header.feelingoJobPopover.manifest')}
          </NavItemButton>
        </PopoverItem>
        <PopoverItem>
          <NavItemButton to={getLanguagePrefix(t('url.sustainableHotel'))}>
            {t('header.feelingoJobPopover.sustainableHotel')}
          </NavItemButton>
        </PopoverItem>
        <PopoverItem>
          <NavItemButton to={getLanguagePrefix(t('url.sustainable'))}>
            {t('header.feelingoJobPopover.sustainableMethod')}
          </NavItemButton>
        </PopoverItem>

      </PopoverMenu>
    </Dropdown>
  );
};
export default FeelingoJobPopover;

import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { media, Button, MainNav, NavLink, MainNavItem } from 'ui';
import { Link } from 'gatsby';
import AuthContext from '../contexts/auth';
import PageContext from '../contexts/page';
import { PopoverMenu, PopoverItem, PopoverItemButton } from 'ui/popover';
import UserPopover from 'components/UserPopover';
import CountryFlagsPopover from 'components/CountryFlagsPopover';
import CountryFlagsMobile from 'components/CountryFlagsMobile';
import FeelingoJobPopover from 'components/FeelingoJobPopover';
import Logofeelingo from '../assets/images/Logofeelingo.svg';
import logo from '../assets/images/colorSimpleLogo.png';
import { useTranslation } from 'react-i18next';
import CountriesContext from 'contexts/country';
import LangPopover from 'components/LangPopover';
import LangPopoverMobile from 'components/LangPopoverMobile';
import Popup from 'reactjs-popup';
import BurgerIcon from './BurgerIcon';
import '../styles/menuburgur.css';
import LeftArrowSlider from '../assets/images/LeftArrowSlider.png';
import listing from '../assets/images/listing.svg';

const LangPopoverResposiveDesign = styled(NavLink)`
  margin-left: 40px;
`;

const ConnectionButton = styled(NavLink)`
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #1a1a1a26 !important;
  border-radius: 28px !important;
`;

const RightMenuResponsive = styled.div`
  display: block;

  ${media.desktop`
    display: none;
  `}
`;

const LeftMenu = styled.div`
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

const Logo = styled(Link)`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: #000;
  margin-right: 32px;

  > img {
    width: 122px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(4)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(2.5)};

  ${media.tablet`
  position: relative ;
  height: 100px;
  z-index: 105;
  top: 0px;
  background-color: white;
  `}
  ${media.mobile`
  position: fixed ;
  height: 80px;
  z-index: 105;
  top: 0px;
  background-color: white;
  padding-top: 33px;
  `}
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: #f7f7f7;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 23px;
  font-weight: bold;
  overflow: auto;
  z-index: 110;
  .h6 {
    margin-bottom: 20px;
  }

  ${PopoverItemButton} {
    font-size: 25px;
    font-weight: bold;
  }

  ${props =>
  props.variant === 'popoverburger' &&
  css`
      background-color: #fff !important;
      padding-top: 0px !important;
      top: 65px !important;
      padding-left: 0px;
      z-index: 105;
    `};
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding: 10px;
`;

const ItemLink = styled(Link)`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: #000;
`;
const DesignTrace = styled.div`
margin-right: 340px;

${media.tabletprod`
margin-right: 580px !important;
margin-top: 16px;
`}

${media.tablet`
margin-right: 439px;

`}
${media.mobile`
margin-right: 0px;

`}
transform: matrix(0, -1, 1, 0, 0, 0);
opacity: 1;`;

const Header = ({ id, variant }) => {
  const { language, getLanguagePrefix } = useContext(PageContext);
  const { countries } = useContext(CountriesContext);
  const { isAuthenticated, handleAuth, handleReset } = useContext(AuthContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open1, ] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const closeModal = () => setOpen(false);
  const closeModal1 = () => setOpen(false);
  const closeModal2 = () => setOpen2(false);
  const closeModal3 = () => setOpen3(false);

  return (
    <Wrapper variant={variant} id={id}>
      <Logo to={language === 'fr' ? '/' : '/'+language}>
        <img src={variant === 'hero' ? Logofeelingo : logo} alt="feelingo" />
      </Logo>
      <LeftMenu>
        <MainNav>
          <FeelingoJobPopover variant={variant}  />
          <CountryFlagsPopover variant={variant} countries={countries} />
          <MainNavItem>
            <NavLink
              to={getLanguagePrefix(t('url.articles'))}
              activeClassName="active"
              variant="howItworks"
            >
              {t('header.blog')}
            </NavLink>
          </MainNavItem>

          <MainNavItem>
            <NavLink
              to={getLanguagePrefix(t('url.becomeHost'))}
              activeClassName="active"
              variant="howItworks"
            >
              {t('pageTitle.becomeHost')}
            </NavLink>
          </MainNavItem>

          <LangPopoverResposiveDesign>
            {' '}
            <LangPopover />
          </LangPopoverResposiveDesign>
          {!isAuthenticated ? (
            <Button variant="outline" onClick={() => handleAuth()}>
              {t('header.login')}
            </Button>
          ) : (
            <UserPopover />
          )}
        </MainNav>
      </LeftMenu>

      <RightMenuResponsive>
        <Popup
          closeOnDocumentClick={false}
          open={open1}
          onClose={closeModal1}
          trigger={open1 => <BurgerIcon open={open1} />}
        >
          <SubMenu variant="popoverburger">
            <PopoverMenu id="panel" variant="menuburger">
              {isAuthenticated && (
                <PopoverItem>
                  <PopoverItemButton
                    as={Link}
                    to={getLanguagePrefix(t('url.account'))}
                  >
                    {t('header.usePopover.profiles')}
                  </PopoverItemButton>
                </PopoverItem>
              )}

              <Popup
                closeOnDocumentClick={false}
                open={open}
                onClose={closeModal}
                trigger={
                  <PopoverItem>
                    <PopoverItemButton onClick={() => setOpen(o => !o)}>
                      {t('newHeader.whoAreWe')}
                      <DesignTrace>
                        <img alt="" src={listing} style={{ width: '12px' }} />
                      </DesignTrace>
                    </PopoverItemButton>
                  </PopoverItem>
                }
              >
                <SubMenu>
                  <MenuItem>
                    <Button variant="nav" onClick={closeModal}>
                      <img alt="" src={LeftArrowSlider} />
                    </Button>
                    <h6>{t('newHeader.whoAreWe')}</h6>
                  </MenuItem>
                  <MenuItem>
                    <ItemLink to={getLanguagePrefix(t('url.aboutUs'))}>
                      {t('header.feelingoJobPopover.aboutUs')}
                    </ItemLink>
                  </MenuItem>
                  <MenuItem>
                    <ItemLink to={getLanguagePrefix(t('url.manifesto'))}>
                      {t('header.feelingoJobPopover.manifest')}
                    </ItemLink>
                  </MenuItem>
                  <MenuItem>
                    <ItemLink to={getLanguagePrefix(t('url.sustainableHotel'))}>
                      {t('header.feelingoJobPopover.sustainableHotel')}
                    </ItemLink>
                  </MenuItem>
                  <MenuItem>
                    <ItemLink to={getLanguagePrefix(t('url.sustainable'))}>
                      {t('header.feelingoJobPopover.sustainableMethod')}
                    </ItemLink>
                  </MenuItem>
                </SubMenu>
              </Popup>

              <Popup
                closeOnDocumentClick={false}
                open={open2}
                onClose={closeModal2}
                trigger={
                  <PopoverItem>
                    <PopoverItemButton onClick={() => setOpen2(o => !o)}>
                      {t('newHeader.destination')}
                      <DesignTrace>
                        <img alt="" src={listing} style={{ width: '12px' }} />
                      </DesignTrace>
                    </PopoverItemButton>
                  </PopoverItem>
                }
              >
                <SubMenu>
                  <MenuItem>
                    <Button variant="nav" onClick={closeModal2}>
                      <img alt="" src={LeftArrowSlider} />
                    </Button>
                    <h6>{t('newHeader.destination')}</h6>
                  </MenuItem>
                  <MenuItem>
                    <CountryFlagsMobile
                      variant={variant}
                      countries={countries}
                    />
                  </MenuItem>
                </SubMenu>
              </Popup>

              <PopoverItem>
                <PopoverItemButton
                  as={Link}
                  to={getLanguagePrefix(t('url.articles'))}
                >
                  {t('header.blog')}
                </PopoverItemButton>
              </PopoverItem>

              <PopoverItem>
                <PopoverItemButton
                  as={Link}
                  to={getLanguagePrefix(t('url.becomeHost'))}
                >
                  {t('pageTitle.becomeHost')}
                </PopoverItemButton>
              </PopoverItem>

              <Popup
                closeOnDocumentClick={false}
                open={open3}
                onClose={closeModal3}
                trigger={
                  <PopoverItem>
                    <PopoverItemButton onClick={() => setOpen3(o => !o)}>
                      {t('newHeader.language')} <LangPopover />
                      <DesignTrace>
                        <img alt="" src={listing} style={{ width: '12px' }} />
                      </DesignTrace>
                    </PopoverItemButton>
                  </PopoverItem>
                }
              >
                <SubMenu>
                  <MenuItem>
                    <Button variant="nav" onClick={closeModal3}>
                      <img src={LeftArrowSlider} alt="" />
                    </Button>
                    <h6>{t('newHeader.language')}</h6>
                    <LangPopoverMobile />
                  </MenuItem>
                </SubMenu>
              </Popup>

              {!isAuthenticated ? (
                <PopoverItem isBorderTop>
                  <ConnectionButton
                    isResponsiveMenu
                    as="button"
                    onClick={handleAuth}
                  >
                    {t('header.login')}
                  </ConnectionButton>
                </PopoverItem>
              ) : (
                <PopoverItem isBorderTop>
                  <ConnectionButton
                    isResponsiveMenu
                    as="button"
                    onClick={handleReset}
                  >
                    {t('header.logout')}
                  </ConnectionButton>
                </PopoverItem>
              )}
            </PopoverMenu>
            {close => <PopoverMenu close={close} />}
          </SubMenu>
        </Popup>
      </RightMenuResponsive>
    </Wrapper>
  );
};

export default Header;

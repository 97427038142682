import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useLocation } from 'react-use';
import styled, { css } from 'styled-components';
import Animate from 'react-move/Animate';
import { ButtonReset, media } from 'ui';
import { PopoverContainer } from 'ui/popover';

const Wrapper = styled.div`
  position: relative;
  display: flex;

  ${props =>
    props.inHeader &&
    css`
      position: static;
      ${media.tablet`
        position: relative;
      `}
    `}
`;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const Dropdown = ({ trigger, triggerIcon, children, ...props }, ref) => {
  const containerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useImperativeHandle(ref, () => ({
    close: () => {
      close();
    },
    open: () => {
      open();
    },
  }));

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  useOnClickOutside(containerRef, close);
  useEffect(close, [location]);

  return (
    <Wrapper {...props} ref={containerRef}>
      <ButtonReset onClick={() => setIsOpen(!isOpen)} left>
        {trigger}
      </ButtonReset>

      <Animate
        show={isOpen}
        start={{ opacity: 0, translate: 10, display: 'none' }}
        enter={[
          {
            opacity: [1],
            timing: {
              duration: 100,
            },
            display: 'block',
          },
          {
            translate: [0],
            timing: {
              duration: 100,
            },
          },
        ]}
        leave={[
          {
            opacity: [0],
            timing: {
              duration: 100,
            },
            display: 'none',
          },
          {
            translate: [10],
            timing: {
              duration: 100,
            },
          },
        ]}
      >
        {({ opacity, translate, display }) => (
          <PopoverContainer
            {...props}
            style={{
              opacity,
              transform: `translateY(${-1 * translate}px)`,
              display,
            }}
          >
            {children}
          </PopoverContainer>
        )}
      </Animate>
    </Wrapper>
  );
};

export default forwardRef(Dropdown);
